/*eslint-disable */
import {bus} from '@/main';
import brainIcon from '@/assets/images/landing/brain.png';
import smartphoneIcon from '@/assets/images/landing/smartphone.png';
import lymphomapIcon from '@/assets/images/landing/lymphomap.png';
import cloudIcon from '@/assets/images/landing/cloud.png';
import aidIcon from '@/assets/images/landing/aid.png';
import hreartIcon from '@/assets/images/landing/hreart.png';
export default {
  name: 'ecg-icon-info',
  components: {},
  props: ['iconInfoIndex'],
  data () {
    return {
      ecgInfo:[
        {
        id:'ecg1',
        title:'Online Therapy',
        content:`<p>Along with your physical well-being, taking care of your mental health is key. As part of our teleconsulting features, 
        you may connect with therapists, 
        globally, to find the necessary care 
        you are seeking for.</p>`,
        icon:brainIcon,
        class:'online_therapy_info'
        },
        {
        id:'ecg2',
        title:'Teleconsulting',
        content:`<p>Our state of the art online consultation
        will allow you to connect with 
        doctors globally in order for you
        to get the much needed care and
        attention you deserve. After booking an appointment on LBIL you will receive a notification
        including reminders of your session.</p>`,
        icon:smartphoneIcon,
        class:'teleconsulting_info'
        },
        {
        id:'ecg3',
        title:'Lymphomap',
        content:`<p>If you are a doctor, researcher or a 
        professional affiliated with a hospital, 
        medical insitution or college/university, 
        we welcome you to check out Lymphomap. 
        Lymphomap is an AI and Machine 
        Learning powered Digital Health Eco-
        system that is created to advance serious
        illness research and treatment.
        The platform is equipped with a digital
        workspace, video conferencing platform, 
        and many more tools to assist you with 
        your work.</p>
        <p class="blurb_txt">Please note that in order to access LBIL’s telemedicine services, your account will be linked to Lymphomap’s platform. This linkage will allow you to directly manage your own appointments, accept appointments, upload prescriptions, host meetings/webinars and all features designed for healthcare professionals.</p>`,
        icon:lymphomapIcon,
        class:'lymphomap_info'
        },
        {
        id:'ecg4',
        title:'Digital Health Records',
        content:`<p>Securely store all of your medical 
        files in one place. Our Digital Health 
        Records (DGH) is a place where you can 
        store all your medical records and access
        them whenever you login to your profile at
        LBIL. 
        Your DGH will also help doctors in 
        instantly accessing your medical history
        seamlessly and hassle free.</p>`,
        icon:cloudIcon,
        class:'digital_hr_info'
        },
        {
        id:'ecg5',
        title:'Digital Prescription',
        content:`<p>After your online consultation, your 
        doctor/specialist will upload a digital 
        prescription upon which you can download
        and use in pharmacies and medical stores
        of your convenience.</p>`,
        icon:aidIcon,
        class:'digital_presc_info'
        },
        {
        id:'ecg6',
        title:'NGOs and Support Groups',
        content:`<p>At LBIL, we understand how important
        it is to receive the right attention and care
        during the post-recovery stage. This goes
        not just for patients but also for caregivers. 
        We have housed a specific designation for 
        our NGOs and Support Groups from all over the world and have 
        created a space for patient and caregiver
        support groups. 
        Connect with various NGOs and Support Groups
        you need as you discuss your triumphs
        and tribulations.</p>`,
        icon:hreartIcon,
        class:'ngo_support_info'
        }
      ]
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    ecgInfoPopupClosed(){
      bus.$emit('iconInfoStateBus', false)
      bus.$emit('landingMainContentBus', true)
    },
    aboutUsPopupEnabled(){
      bus.$emit('aboutUsPopupStateBus', true)
      bus.$emit('iconInfoStateBus', false)
    }
  }
}