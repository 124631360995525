<template>
    <router-view></router-view>
</template>

<script>
/*eslint-disable */
import { bus } from '@/main';

export default {
  name: 'publicHome',
  components: {},
  props: [],
  data() {
    return {
    }
  },
  computed: {

  },
  mounted() {
    bus.$emit('headerDisabledBus', false);
    if (localStorage.getItem('token') != null && localStorage.getItem('LoginedUserID') != null) {
      this.$router.push('/dashboard/');
    }
  },
  beforeRouteLeave: function (to, from, next) {
    bus.$emit('headerDisabledBus', true);
    next();
  },
}
</script>