/*eslint-disable */
const $ = window.jQuery = require('jquery');

import {bus} from '@/main';
import ecgIconInfo from './ecgIconInfo/';
import welcomeAudioPlay from '@/assets/audio/welcome_record_new.mp3';

export default {
  name: 'landing',
  components: {
    'icon-info': ecgIconInfo,
  },
  props: [],
  data () {
    return {
      landingMainContent:true,
      loginPopupState:false,
      iconInfoState:false,
      iconInfoId:null,
      signupPopupState:false,
      aboutUsPopupState:false,
      authBtnShow:true,
      firstTimeVisit: sessionStorage.getItem('firstTimeVisit'),
    }
  },
  computed: {

  },
  mounted () {
    bus.$emit('headerDisabledBus', false);
    if (localStorage.getItem('token') != null && localStorage.getItem('LoginedUserID') != null) {
      this.$router.push('/dashboard/');
    }
    if (this.firstTimeVisit === null) {
      this.playSound(welcomeAudioPlay)
      sessionStorage.setItem('firstTimeVisit', 'visited')
    }
    if(this.$store.getters.isAuthenticated){
      this.authBtnShow = false
    }
    bus.$on('iconInfoStateBus', (data) => {
      this.iconInfoState = data
    });
    bus.$on('landingMainContentBus', (data) => {
      this.landingMainContent = data
    });
  },
  methods: {
    playSound(sound){
      if(sound){
        var audio = new Audio(sound);
        audio.play();
      }
    },
    ecgInfoPopupEnabled(Id){
      this.iconInfoId = Id
      this.landingMainContent = false
      this.iconInfoState = true
    },
  },
  beforeCreate: function () {
    document.body.classList.add('ladning_page')
  },
  beforeRouteLeave: function (to, from, next) {
    document.body.classList.remove('ladning_page')
    next();
  },
}